<script setup lang="ts">
import IconButton from "~/components/IconButton.vue";
import CreateOrgModal from "~/features/orgs/CreateOrgModal.vue";
import { useOrgStore } from "~/store/orgs";
import { generateEntityId } from "~/types/helpers";
import type { Org } from "~/types/types";
const store = useOrgStore();
const isModalOpen = ref(false);

async function submitted(org: Org) {
  await store.addOrg(org);
  isModalOpen.value = false;
}

const defaultPlatforms = [
  "Facebook",
  "Instagram",
  "Tiktok",
  "LinkedIn",
  "Reddit",
  "Dev.to",
  "Medium",
];

const defaultContentTypes = [
  {type: "caption", prompt: "Write a 'caption'."},
  {type: "summary", prompt: "Write a 'summary'."},
  {type: "blog article", prompt: "Write a 'blog'."},
  {type: "10 interview questions", prompt: "Write a 10 interview questions."},
  {type: "quiz", prompt: "Write a 'quiz'."},
  {type: "opinion piece", prompt: "Write a 'opinion piece'."},
  {type: "hashtags", prompt: "Generate relevant hashtags."},
];

const defaultOrg = (): Org => ({
  _id: generateEntityId(),
  training: "",
  platforms: defaultPlatforms,
  contentTypes: defaultContentTypes,
  name: "",
  created: new Date(),
  settings: { }
});
</script>

<template>
  <span
    ><IconButton
      @click="isModalOpen = true"
      type="add"
      size="sm"
      color="primary"
      square
      variant="link"
      title="Create New"
      ></IconButton>
    <CreateOrgModal
      v-if="isModalOpen"
      @close="isModalOpen = false"
      @submitted="submitted"
      :org="defaultOrg()"
  /></span>
</template>
