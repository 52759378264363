<template>
  <div>
    <!-- <Metadata :description="page.description" :image="page.image"></Metadata> -->
    <!-- <Nav :links="links"></Nav> -->
    <Section class="mt-3">
      <div class="text-2xl font-bold mb-5">Organizations <CreateOrg /></div>

      <table
        class="w-full text-left rtl:text-right text-gray-500 dark:text-gray-400"
      >
        <thead>
          <tr>
            <th>Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="org in orgs">
            <td>
              <NuxtLink :to="`/${org._id}`">{{ org.name }}</NuxtLink>
            </td>
          </tr>
        </tbody>
      </table>
    </Section>
  </div>
</template>

<script setup lang="ts">
import CreateOrg from "~/features/orgs/CreateOrg.vue";
import { useOrgStore } from "../store/orgs";
const store = useOrgStore();
const orgs = computed(() => store.getOrgs);

// async function updateRecordingSessionSettings(org: Org, recordingSessionSettings: RecordingSessionSettings){
//   const updated = {
//     ...org,
//     settings: { ...(org.settings||{}), recordingSessions: recordingSessionSettings }
//   }
//   await store.updateOrg(updated);
// }
</script>

<style scoped>
/* CSS specific to this page */
</style>
